import React from 'react';
import zIndexConf from '../../common/zIndexConfig';


export default class ProductIntroduction extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){

    }

    render(){

        return(
            <div style={{width: '100vw',height:'100vh'}}>
                <div style={{padding:'0px 11px 0',boxSizing:'border-box',display:'flex',flexDirection:'column',marginTop:'70px'}}>
                    <span style={{color:'#333333',fontSize:'16px',fontWeight:'bold'}}>覆盖城市</span>
                    <span style={{color:'#979797',fontSize:'12px'}}>Covered city map</span>
                </div>
                <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'30px',zIndex:zIndexConf}}>
                    <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/ba6b9e89e98f4e33bd7ada351d1968c5' alt='' style={{width:330,height:390,}} />
                </div>
                
            </div>
        );
    }
    
}