import React from 'react';
export default class NewsDetail extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
    
        };
    }

    componentDidMount(){
        
    }

    showHome(){
        this.props.history.goBack();
    }

    render(){
        return(
            <div  onClick={this.showHome.bind(this)}>NewsDetail123123123123</div>
        );
    }
}