import React from 'react';
import Swipernews from 'swiper';
import '../../../node_modules/swiper/css/swiper.min.css'
import '../../../node_modules/swiper/js/swiper.min.js';
import './news.scss'
export default class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.newSwiper = new Swipernews('.swiper-news', {
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-nextnews',
                prevEl: '.swiper-button-prevnews',
            },
            pagination: '.swiper-pagination',
            slidesPerView: 'auto',
            paginationClickable: true,
            spaceBetween: -100
        })
    }

    render() {

        return (
            <div style={{ width: '100vw', height: '100vh' }}>
                <div style={{ padding: '0px 11px 0', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', marginTop: '70px' }}>
                    <span style={{ color: '#333333', fontSize: '16px', fontWeight: 'bold' }}>新闻中心</span>
                    <span style={{ color: '#979797', fontSize: '12px' }}>News Center</span>
                </div>
                <div style={{ marginTop: '25px' }}>
                    <div style={{ position: 'fixed', marginLeft: '33px' }}>
                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/7525a1325afb47118fcc32e7ddc57a94" alt='' style={{ width: 30, height: 30, }} />
                    </div>
                    <div className="swiper-container swiper-news">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://baijiahao.baidu.com/s?id=1668378016669013861&wfr=spider&for=pc' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/1e161989565e4883a8d8cf41a3208437' alt='营业执照' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">货圈全发布“流动小卖部”扶持计划 用数字化手段激活“地摊经济”</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 06 / 02</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/oJDS3Z_h3Mq9hT2O3qE_dg' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/7d4b1e123378468c931c43dd5c51b158' alt='营业执照' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">助力地摊经济，“货比三价”开放个人进货入口啦！</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 06 / 01</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/dhaiCDrwB-NHfjvn8IUUFg' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/feef13969c20483ca03a4333eb211228' alt='营业执照' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">连锁便利店接连大规模关店 加盟商何去何从</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 05 / 12</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/may0K7CT7TDoXAk1rPo6vA' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/26abf76c176647fd89e91588b2a825f1' alt='营业执照' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">从别人家的业务员到自己家的业务员，继承家业的吕老板能否在20年向上生长</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 05 / 08</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/wRIZHZAE6-MF0FjRFTSxcA' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/5e79c593f1fe4a81a49b734581262db8' alt='营业执照' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">2020年开个杂货铺需要哪些证件？看完秒懂</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 04 / 20</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/vUYb9eICudCUCwUW1r_cRg' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/d3dc55bd62f7463a99dfe9fbb0da56fe' alt='钱' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">生意不好做？不光是因为没钱！</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 04 / 03</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/wN412bdO2QEVUlOoptTKpg' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/d2966f50c6e743fd99eb05d961d4c540' alt='弱肉强食' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">弱肉强食的时代已经来临！看批发老板如何从0到1入局社区团购</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 03 / 27</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/atodNRWhiuPH8hRex490NQ' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/94fd191598c5419fa05e47c30c0b1c64' alt='社区团购' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">各大零售巨头瞄准“社区团购”，超市小店该何去何从</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 03 / 23</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/vP4wqQCeVjGDLcRpYNnHFg' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/d548d2d04a7c41d8b07911223c7b59a1' alt='他们' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">节日快乐丨因为你们，我觉得人间值得</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 03 / 15</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/d_cBaBs42YSpOCphMkKy2w' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/1c01469beb9344519ebb2bd38f268c60' alt='他们' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">疫情期间，为何订单量源源不断？</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 03 / 13</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/CSuRTMJZ2eNtKZhqGrDWwA' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/0af075aab0db4fa9a8f42fa0b3dd0ad9' alt='他们' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">10年前38万可以《安家》，10年后6.5万/平米还安不了家……</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 03 / 10</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/9UpjMayGHduQhCEj5nTsfA' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/8bece50545b94d02845fd1e277daaabf' alt='他们' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">“店大欺客”，你以为的便宜只是你以为</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 03 / 10</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/IURm8HLCXlKq0zRAW1sB8A' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/96de9afa9e9d438ea53eead0f65c5eee' alt='他们' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">我的天哪！他们竟然......</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 02 / 22</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/LEsVQiEvaV5aibSjcZWUpw' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/6f0409609a3e4792b22acaeac124f481' alt='老板' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">不关门！不涨价！向保障民生的小店老板们致敬！</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 02 / 10</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/bnXB8JG8VgCtOi1E228gwg' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj.test.hqq.vip/9e0df7b5c3984a6785d1e6e1d1490225' alt='武汉' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">31省市地标建筑，元宵节亮灯为武汉加油！</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020 / 02 / 09</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/Bns4daVZmTN0nPqer6FkTg' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/355f56c8fe314656a7d62b0f719a9990' alt='政府' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">2020，测测你的店铺吸金力有多少！</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2020/ 01 / 01</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/UTNkspfmTxY07nHWKXbYPA' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/502fe6f8469f4c1db152ff38464ea296' alt='送货员' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">17年，他从送货员做到了老板！</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 12 / 21</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>



                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/YT9Avxxp00DqM9yiLw81qw' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/1cd30f344d954fc0b191b1bad25fe635' alt='爱情' className="news-slide-pic" />
                                            </div>

                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">十个片段，让你看懂爱情。</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 12 / 17</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/Y8NpBGuedjP6omo3PIbP_w' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/56bf606acc8a4ec2be0024ededac9593' alt='合伙人' className="news-slide-pic" />
                                            </div>

                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">从20到300多个客户，他们做对了这些事</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 12 / 14</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/ycNNbo8tQd_qTZiIy2ANnw' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/515b87dccd934f43917acdcb406ce34f' alt='防骗' className="news-slide-pic" />
                                            </div>

                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">防骗丨开店1年被骗2万块，他总结了7点经验！</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 11 / 28</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/SdJdZmVoZeylQ2XNv7wOgw' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/568cdd8df14c404fb703016aca4ee635' alt='便利店' className="news-slide-pic" />
                                            </div>

                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">为什么有人放弃年薪20万的工作去开便利店？便利店有多赚钱？</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 11 / 26</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/n5b6TpL6AHeoeNdnnhMYOA' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/6128197f73104506b4db6c4298e91bf8' alt='鼠疫' className="news-slide-pic" />
                                            </div>

                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">北京突发鼠疫？别慌！你需要知道的都在这……</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 11 / 14</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251, height: 251 }}>
                                        <a href='https://baijiahao.baidu.com/s?id=1637187211919426842&amp;wfr=spider&amp;' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/e61ad67a32444fc2af37dbd40d74d18c' alt='政府' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[媒体报道] 津南区海棠众创大街启动运营</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 06 / 24</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='http://www.sohu.com/a/254160602_99890220' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/8cd94535102b45509db141da2fdc1d87" alt='新经销' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[媒体报道] 货圈全总裁庄菁：从中国互联网发展看快消行业 </p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2018 / 09 / 16</span>
                                                </div>
                                            </div>
                                        </a>

                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://36kr.com/p/5083579' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/886ece7988c94b448b5cf2632eac021a" alt='融资' style={{ width: 250, height: 250, borderRadius: '100%' }} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div>
                                                    <p className="news-slide-text-media">[媒体报道]Update | 为杂货铺提供比价工具 </p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2017 / 07 / 14</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='http://www.bbtnews.com.cn/2017/0418/189673.shtml' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/32582bd589384a65a063ddd5a0a12e7b" alt='超市店铺' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[媒体报道] 夫妻店需要信息化提升效率 </p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2017 / 04 / 18</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='http://static.nfapp.southcn.com/content/201704/17/c375373.html' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/f26bc520ca274364a64015777077bc7f' alt='电商' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[媒体报道] 马云拿他们也没办法</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2017 / 04 / 17</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='http://appdetail.netwin.cn/web/2017/04/79b6f6e05c11eff7d5a544ad405218e2.html?from=singlemessage&isappinstalled=0' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/90d18566b0674f89a7735d8346e9895e' alt='进货' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[媒体报道] 深圳3万家杂货铺面临涅磐契机</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2017 / 04 / 17</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href="http://m.21jingji.com/article/20170410/herald/a1357f423ab4800c354e378371765d2f.html" style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/7d063ce7e51a4664a559470a3f8fa8d3' alt='快消品市场' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[媒体报道] 货圈全张泽：杂货铺市场也有十万亿规模</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2017 / 04 / 10</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='http://www.cyzone.cn/article/158572.html' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/6bd2a639a5514d7ba5bf6d49500e1734" alt='订单' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[媒体报道] 从零到一做出去哪儿酒店业务的张泽</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2017 / 03 / 14</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://www.yicai.com/news/5222594.html' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/94428709e3be4212a52a11e8f82bfd3c ' alt='便利店' style={{ width: 250, height: 250, borderRadius: '100%' }} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div>
                                                    <p className="news-slide-text-media">[媒体报道]去哪儿前高管看上了便利店生意 </p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2017 / 02 / 12</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://36kr.com/p/5060649' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/c655d6cc4d70441caf38a1767277ef27" alt='比价' style={{ width: 250, height: 250, borderRadius: '100%' }} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div>
                                                    <p className="news-slide-text-media">[媒体报道]去哪儿网酒店创始人张泽转型快消品B2B</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2016 / 12 / 30</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/9tT74aU7Wh8nDqXMGo8onQ' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/a372d88024444372b47faf271c7ccdd5' alt='赚钱' style={{ width: 250, height: 250, borderRadius: '100%' }} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div>
                                                    <p className="news-slide-text-media">[媒体报道]不安分的张泽 </p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2016 / 12 / 19</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/soU8AoBGHdiX4fN-jplGLg' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/45bbd310f2d346ee8071cb4a15b424bf" alt='零售' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] U中国快消品大会丨货圈全庄菁：不会做简法的互联网做不好快消零售 </p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 09 / 12</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/KhX9Vuhwj9ZI0YZKyV4QqQ' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/2d7f7d7bd53841268f0ab13de0907c18' alt='探探' style={{ width: 250, height: 250, borderRadius: '100%' }} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div>
                                                    <p className="news-slide-text-media">[货新闻]已婚男士还在玩左划右划的app？</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 10 / 24</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/uy06oPaz1beLeAFdhaiFNw' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/a91ab33ddf6d4712acf038ae858d2997' alt='老板' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 还在谈996？我们开店的老板可都是007！ </p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 04 / 17</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/ADIPHHAWAlWYXCDpnWuxIA' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/7f0058a4dcd0426991ddfa7d309b7b5f" alt='京东' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 盘点11个加盟品牌便利店，选择困难症？</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2018 / 09 / 06</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/MzrN_PQ9-sTm_8Gm-Y_G5g' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/5d5ad3bcfa284d22bd223a90630a8ef2" alt='烟酒' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 小店日记|便利店灯光越亮越好？烟柜咋防偷？开店小技巧</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2018 / 06 / 20</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/h-oqQk0SSmQNCwCiTcX2NQ' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/8f348df888c6458caa450fc7e3a04eb1' alt='天猫' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 毛利一年新增40万？我到底要不要加盟天猫小店？</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2017 / 09 / 13</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/5_b1kno7MuTAP3CFTradsg' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/31accac2948e4754be58704729ac91f8" alt='加盟' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 20家便利店加盟盘点|雅堂已死，天猫京东惠民，加盟到底选哪家？ </p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2018 / 03 / 21</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/Wd1ycWfUP_UHLTuxQDe4KQ' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/a30f2d6f2e86433da148739e51cdc1ed' alt='货源' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 天猫小店PK京东便利店，你会选择谁？ </p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2018 / 08 / 22</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/ZuEN0Qy0wcYjeK5ffL0k2w' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/412e3ba5977542e6a03e794863bbffd4' alt='杂货铺' style={{ width: 250, height: 250, borderRadius: '100%' }} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div>
                                                    <p className="news-slide-text-media">[货新闻]杂货铺影视年鉴，从1940-2017年的杂货铺都是什么样？ </p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2017 / 11 / 30</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/J05FtZ4yZI1AemeFe0K1SA' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/3c134b263c464ae88284ab9864b804dd" alt='装修' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 实拍！我们花100分钟改造这家杂货铺，前后对比（惊呆了！）</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2017 / 06 / 09</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/uOS0z7tTS-O9oLY4QwHqqw' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/ad847c400f01481d98dcdd7279c1114e" alt='进货' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 深夜食堂在中国的故事，其实全都发生在杂货铺里。</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2017 / 06 / 21</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/o0vvd29bPfOkcZe4YtAQSA' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/57f9df87313b443cae8c49f9f96b881d' alt='饭店' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 炸油条都年入30万了，你还在抱怨批发不好做？ </p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 06 / 28</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/S00Q7DYDUdAVhbzWw8mYOQ' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/683953a28a974b63a01b0cf7b7daa840" alt='食品批发' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 霸道”卫龙：辣条竞争加剧 卫龙要求经销商二选一</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 04 / 05</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/DDR3cG37XRWolduVUiK_nA' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/e249875482354b2ab99ed3a5fcaae202" alt='饮料' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 批发周报丨三全、科迪、金锣等11企业疑检出非洲猪瘟饺子香肠</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2019 / 02 / 22</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/Qz1Qe2RX7297rCV5S8xM6A' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/b61d3146d3764fc5aeff973a750d9d9b" alt='新品饮料' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 饮料篇丨盘点19年批发商必进的25款新品饮料！</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2018 / 12 / 28</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/gUBNdeR7IeeYpjj8RRUXnw' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/3811b7575a8d4ea9bdc5c0519d3de627" alt='批发' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 夜访批发部丨1夜之间解决滞销100件火腿肠的秘诀是……</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2018 / 10 / 12</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ width: 251 }}>
                                        <a href='https://mp.weixin.qq.com/s/iEGWkkybXzyJcHqu9CpLog' style={{ textDecoration: 'none', color: '#121314' }}>
                                            <div style={{ width: 251, height: 251, borderRadius: '100%' }}>
                                                <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/331c24c3d6ef46faab6dd0561e34a3a0' alt='倒闭' className="news-slide-pic" />
                                            </div>
                                            <div >
                                                <div className="news-slide-text">
                                                    <p className="news-slide-text-media">[货新闻] 盘点|2018年倒闭品牌便利店，您家的货款结清了么？</p>
                                                    <span style={{ color: '#979797', fontSize: '10px' }}>2018 / 10 / 05</span>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* <!--左箭头。如果放置在swiper-container外面，需要自定义样式。--> */}
                    <div className="swiper-button-prev swiper-button-prevnews" style={{ outline: 'none', fontWeight: 'bold', position: 'absolute', marginTop: '130px', marginLeft: '130px', padding: 10 }}>

                    </div>
                    {/* <!--右箭头。如果放置在swiper-container外面，需要自定义样式。--> */}
                    <div className="swiper-button-next swiper-button-nextnews" style={{ outline: 'none', fontWeight: 'bold', position: 'absolute', marginTop: '130px', marginRight: '130px', padding: 10 }}>

                    </div>

                </div>
            </div>
        );
    }

}