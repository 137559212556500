import React from "react";
import {
  HashRouter,
  Route,
  Switch,
} from "react-router-dom";

// 首页
import Home from "./pages/home/home";
// 新闻详情
import NewsDetail from "./pages/newsdetail/newsdetail";

const WebRouter = (
    <HashRouter>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/newsdetail" component={NewsDetail} />
        </Switch>
    </HashRouter>
);
export default WebRouter;
