import React from 'react';
import zIndexConf from '../../common/zIndexConfig';
import Swiper from 'swiper';
import '../../../node_modules/swiper/css/swiper.min.css'
import '../../../node_modules/swiper/js/swiper.min.js';
import './product.scss'

export default class ProductIntroduction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.productswiper = new Swiper('.container', {
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-nextproduct',
                prevEl: '.swiper-button-prevproduct',
            },
            pagination: '.swiper-pagination',
            slidesPerView: 'auto',
            paginationClickable: true,
            spaceBetween: -80
            // slidesPerView : 3,
        })
    }
    render() {

        return (
            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ padding: '0 11px 0', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', marginTop: '70px' }}>
                    <span style={{ color: '#333333', fontSize: '16px', fontWeight: 'bold' }}>产品介绍</span>
                    <span style={{ color: '#979797', fontSize: '12px' }}>Product introduction</span>
                </div>
                <div className="swiper-container container">
                    <div className="swiper-wrapper">
                    <div className="swiper-slide slide">
                          <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="http://m.huoquanquan.cn/shenpi.html" style={{ textDecoration: 'none', color: '#ffffff' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', marginTop: '59px', paddingTop: '15px' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/945a5fadb6514c8d8e7e98f1364b97a0' alt='' style={{ width: '270px', height: '133px', position: 'absolute', top: 0, boxShadow: '0 5px 15px 0 rgba(0,0,0,0.2)' }} />
                                        <div style={{ zIndex: zIndexConf.text, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/527bc3ff0d3c44ae938e1834fa1f1e6e' alt='' style={{ width: '45px', height: '45px' }} />
                                            <span style={{ color: '#ffffff', marginTop: '13px', fontSize: '14px' }}>批发商网上开店工具</span>
                                            <span style={{ color: '#ffffff', marginTop: '2px', fontSize: '14px' }}>主要服务快消渠道的中间环节</span>
                                        </div>
                                    </div>
                                </a>
                          </div>
                        </div>
                       
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="http://hbsj.hqq.vip/" style={{ textDecoration: 'none', color: '#494444' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', marginTop: '59px', paddingTop: '15px' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/c0d7ed1d180947f29e16eea50f77f489" alt='' style={{ width: '270px', height: '133px', position: 'absolute', top: 0, boxShadow: '0 5px 15px 0 rgba(0,0,0,0.2)' }} />
                                        <div style={{ zIndex: zIndexConf.text, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <img src="https://hbsj.test.hqq.vip/eeffe1a9d9c84bfd8e9511faedb62a3a" alt='' style={{ width: '45px', height: '45px' }} />
                                            <span style={{ color: '#494444', marginTop: '13px', fontSize: '14px' }}>杂货铺进货比价工具</span>
                                            <span style={{ color: '#494444', marginTop: '2px', fontSize: '14px' }}>主要服务快消渠道的末端节点</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://www.daandata.com/" style={{ textDecoration: 'none', color: '#fff' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', marginTop: '59px',paddingTop: '15px' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/4fed968a2ffc4197ac87b0170774a277' alt='' style={{ width: '270px', height: '133px', position: 'absolute', top: 0, boxShadow: '0 5px 15px 0 rgba(0,0,0,0.2)' }} />
                                        <div style={{ zIndex: zIndexConf.text, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {/* <span style={{ fontSize: '24px', color: '#ffffff', marginTop: '20px' }}>HQ.Q-Digital</span> */}
                                            <img src="https://hbsj.test.hqq.vip//34ed189773a7494e83ec645afd108a3b" alt='' style={{ width: '45px', height: '45px'}} />
                                            {/* <img src={window.location.origin + "/images2/answerLogo.png"} alt='' style={{ width: '45px', height: '45px' }} /> */}
                                            <span style={{ color: '#ffffff', marginTop: '20px', lineHeight: '20px', fontSize: '14px' }}>答案-货圈全旗下快消智库</span>
                                            <span style={{ color: '#ffffff', fontSize: '14px' }}>主要服务快消渠道起点</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--左箭头。如果放置在swiper-container外面，需要自定义样式。--> */}
                <div className="swiper-button-prev swiper-button-prevproduct" style={{ outline: 'none', fontWeight: 'bold', position: 'absolute', marginTop: '130px', marginLeft: '130px', padding: 10 }}>

                </div>
                {/* <!--右箭头。如果放置在swiper-container外面，需要自定义样式。--> */}
                <div className="swiper-button-next swiper-button-nextproduct" style={{ outline: 'none', fontWeight: 'bold', position: 'absolute', marginTop: '130px', marginRight: '130px', padding: 10 }}></div>
            </div>
        );
    }

}


/* <div style={{display:'flex',justifyContent:'center',alignItems:'center',position:'relative'}}>
       <img src={images.productBackground}  alt='' style={{width:'270px',height:'133px',position:'absolute',top:0}} />
       <div style={{zIndex:zIndexConf.text,display:'flex',flexDirection:'column',alignItems:'center'}}>
           <span style={{fontSize:'24px',color:'#ffffff',marginTop:'20px'}}>HQ.Q-Digital</span>
           <span style={{color:'#ffffff',marginTop:'20px',lineHeight:'20px'}}>快消渠道数字化商业智能解决方案</span>
           <span style={{color:'#ffffff'}}>主要服务快消渠道的起点</span>
       </div>
   </div> */