import React from 'react';
import zIndexConf from '../../common/zIndexConfig';



export default class Header extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            pageName:'home',//"home":首页  "other"：其他
            currentIndex:props.currentIndex?props.currentIndex:0
        };
    }

    componentDidMount(){

    }
    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            currentIndex:newProps.currentIndex
        })                                                                                                                                                                                                                                                                                                                                                                                         
    }
    showMenu(){
        if(this.props.showMenu){
            this.props.showMenu();
        }
    }

    render(){
        return(
            <div style={{width: '100vw',height:44,backgroundColor:'transparent',display:'flex',flexDirection:'row',justifyContent:'space-between',position:'fixed',zIndex:zIndexConf.header}}>
                <img style={{width:96,height:32,marginTop:10,marginLeft:10}} src={this.state.currentIndex===0||this.state.currentIndex === 5 ?"https://hbsj-test.oss-cn-beijing.aliyuncs.com/4a7a990ce0f54a6a91614f09638663fa":"https://hbsj-test.oss-cn-beijing.aliyuncs.com/0ffa504abeef4438896ce053d62e3e77"} alt=''/>
                <img style={{width:40,height:40}} src={this.state.currentIndex===0||this.state.currentIndex === 5?"https://hbsj-test.oss-cn-beijing.aliyuncs.com/8bb552ff666e41d09f114c4d410a2939":"https://hbsj-test.oss-cn-beijing.aliyuncs.com/225996b550174e708d44dfb7f9b2d699"} alt='' onClick={this.showMenu.bind(this)}/>
            </div>
        );
    }
    
}