import React from 'react';
import colors from '../../common/color';
import zIndexConf from '../../common/zIndexConfig';


export default class MainView extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){

    }
    download(index){
        if(this.props.showMainIndex){
            this.props.showMainIndex(index);
        }
    }

    render(){

        return(
            <div style={{width: '100vw',height:'100vh',justifyContent:'center',alignItems:'center',display:'flex'}}>
                <img style={{width:'100vw',height:'100vh',position:'absolute',zIndex:zIndexConf.bg}} src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/484cca6c59b24ccd836f9a71430eb0d2" alt=''/>
                <div style={{width:284,height:284,backgroundColor:colors.alphhalfbg,borderRadius:142,marginTop: '-80px',zIndex:zIndexConf.content}}>
                    <div style={{fontSize:30,color:colors.white,marginTop:102,marginLeft:32}}>快消品大数据平台</div>
                    <div style={{fontSize:16,color:colors.white,marginTop:10,marginLeft:32}}>持续提升零售业效率</div>
                    <div style={{fontSize:11,color:"#B5B9BB",marginTop:29,marginLeft:32,display:'flex',flexDirection:'row',alignItems:'center'}} onClick={this.download.bind(this,5)}><img style={{width:16,height:15,marginRight:4}} src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/5fc1f828ba6640bda32b47cc1ed12d48" alt=''/>下载客户端</div>
                </div>
            </div>
        );
    }
    
}