import React from 'react';
import Swipermic from 'swiper';
import '../../../node_modules/swiper/css/swiper.min.css'
import '../../../node_modules/swiper/js/swiper.min.js';
import './MicrocosmicLaboratory.scss'

export default class MicrocosmicLaboratory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0
        };
    }

    componentDidMount() {
        let that = this;
        this.microcosswiper = new Swipermic('.microcos-swiper', {
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-nextmic',
                prevEl: '.swiper-button-prevmic',
            },
            on: {
                slideChangeTransitionEnd: function (options) {
                    that.setState({
                        currentIndex: this.activeIndex
                    })
                }
            },
            pagination: '.swiper-pagination',
            slidesPerView: 'auto',
            paginationClickable: true,
            spaceBetween: -80
        })
    }

    render() {
        return (
            <div style={{ width: '100vw', height: '100vh' }}>
                <div style={{ padding: '0px 11px 0', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', marginTop: '70px' }}>
                    <span style={{ color: '#333333', fontSize: '16px', fontWeight: 'bold' }}>微数实验室</span>
                    <span style={{ color: '#979797', fontSize: '12px' }}>Microcosmic Laboratory</span>
                </div>
                <div className="swiper-container microcos-swiper">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/BKnpjkDc7NKktgXfFurGEg" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj.test.hqq.vip/af26f147937645a7b14f53380090cbee" alt='快消品' className="microcos-slide-img" />
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="microcos-slide-text">数据报告|爱豆成团风潮下，饮料也组团出道了</span>
                                        <span className="microcos-slide-times">2020/06/04</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/TPCqoYDTsAr1Bdiho_u3Gw" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj.test.hqq.vip/e87e13a5828f4ff8a05d11b4326ac48d" alt='快消品' className="microcos-slide-img" />
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="microcos-slide-text">数据报告丨冰淇淋逆势走红，让数据告诉你如何搭车才能做赢家</span>
                                        <span className="microcos-slide-times">2020/05/08</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/duy_7Paq4kKgabQtTDDm6g" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj.test.hqq.vip/47a5abaf6a814f86b0f582cebcb9b9b2" alt='快消品' className="microcos-slide-img" />
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="microcos-slide-text">数据报告 | 疫情之下我们身边的小卖部/杂货铺怎么样了？</span>
                                        <span className="microcos-slide-times">2020/04/20</span>
                                    </div>

                                </a>
                            </div>
                        </div>

                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/3FAMw3G9BNBeqjqAZForxQ" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj.test.hqq.vip/d1b58cf7944d47f0ad4470a814c7514e" alt='快消品' className="microcos-slide-img" />
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="microcos-slide-text">数据报告 | 万万没想到“酵母”成为2C渠道断货王，2B渠道又如何？</span>
                                        <span className="microcos-slide-times">2020/03/14</span>
                                    </div>

                                </a>
                            </div>
                        </div>

                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/nDMVbutxmr75KOGshNQ2Dg" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj.test.hqq.vip/1d8e4b9906ca41298e672160145f565c" alt='快消品' className="microcos-slide-img" />
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="microcos-slide-text">数据报告丨疫情下“社区团购”大显神通，快消渠道又要变天？</span>
                                        <span className="microcos-slide-times">2020/02/09</span>
                                    </div>

                                </a>
                            </div>
                        </div>

                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/VdSTOxz6M2uQ4eAlmVgizA" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/f98c8091ab0048afa132a853abfa73a9" alt='快消品' className="microcos-slide-img" />
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="microcos-slide-text">快消渠道数字化转型发展报告：当数据遇到快消品</span>
                                        <span className="microcos-slide-times">2020/01/07</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/xiar_jgRxwFxO28GSgmAnw" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/6d055969ef2f46f5a306ac5bd44783c9" alt='快消品' className="microcos-slide-img" />
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="microcos-slide-text">为什么猪肉涨价那么多，火腿肠还是1块1根？</span>
                                        <span className="microcos-slide-times">2019/12/04</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/8_ne2x_kDZpUUddRWLfCAQ" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/670f0595996f40dea8c26a87db79b8a4" alt='双十一' className="microcos-slide-img" />
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="microcos-slide-text">对比历史最低价！双十一89%的价格竟不便宜！</span>
                                        <span className="microcos-slide-times">2019/11/07</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/WtM9Y26d1EZoGscIkWN1kQ" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/9753104c44ac4fe5bcad50633c645644' alt='国货' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Hbsj} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">数据报告丨90年代前的50大国货品牌</span>
                                        <span className="microcos-slide-times">2019/10/04</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/W0NjkEq2V6aRtRT8mSBzDQ" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/2e0ded2689e94f23b154801993fb6264" alt='啤酒' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">数据报告|啤酒企业半年报亮眼！B2B渠道对业绩贡献能有多少？</span>
                                        <span className="microcos-slide-times">2019/09/12</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/4sM4cIKTwZzlihhPdvGlpA" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/6dddcd12d45d46a68b5224ee261f2a63" alt='陈列' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">大数据揭秘，快消品B2B铺货究竟有哪些门道？</span>
                                        <span className="microcos-slide-times">2019/08/08</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/UT5TZQmB9HuoKVk7Qs2MhQ" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/51b740f874884dce971ec98d40e4eda5" alt='功能饮料' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">数据报告|分析了近两年功能饮料市场，我们发现...</span>
                                        <span className="microcos-slide-times">2019/07/05</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/g0xCgByQmHjXNIO1ST4BNw" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/00bf93993c8a4b06a27865285eb83377' alt='方便面' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">数据报告丨追求“泡面自由”的时代到了？</span>
                                        <span className="microcos-slide-times">2019/06/05</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/pb1UE9hutK2x5-5jAqYDnw" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/dfe5e5c60c224ce8b0125213ca30c2bf' alt='牛奶' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">数据报告|千亿乳业市场全剖析 ——伊利一家独大，最受欢迎单品占到63%！</span>
                                        <span className="microcos-slide-times">2019/05/01</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/1opIh7ouZkvqkHc3yiuLgg" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/478d4210c45c47228c0c941ff9f3ebc4' alt='糖酒会' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">数据报告丨数解糖酒会，行业地理报告</span>
                                        <span className="microcos-slide-times">2019/04/04</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/bRYY4Vbuic7E_r4oSKY-kA" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/d619229241de497ebbb3652dffe3328e" alt='零售' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">数据报告|当互联网新贵遭遇零售老兵</span>
                                        <span className="microcos-slide-times">2019/02/13</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/H0hesTfGOFYUFElqoGbRQQ" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/383e88c7d1bb42a58e2fc7439d5758bb" alt='夫妻' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">数据报告丨夫妻店PK便利店 究竟谁会赢得万亿市场？</span>
                                        <span className="microcos-slide-times">2019/01/09</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/JfLu8LFVQa0Ci3wvjK_rew" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/bc430b34822747bcb4ec03689be62895" alt='品牌商' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">这些年品牌商与快消B2B的“恩怨情仇”</span>
                                        <span className="microcos-slide-times">2018/12/12</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/j0yAgL_Xd05mhgplS3h3Hw" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/667081e35baf46d98e7062c2f01ff5f3" alt='优惠' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">双十一简直是成年人的数学考试！</span>
                                        <span className="microcos-slide-times">2018/11/08</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/VT8L7cgCKas7NSuGlrFRRg" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/c03d9121df9241fb9bcabd876cbdecc0" alt='批发' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">数据报告丨全面剖析批发商，经常送货的批发商竟然是80后！</span>
                                        <span className="microcos-slide-times">2018/10/07</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/ELLor4n4JIRtKgdg1QEFfA" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/ceb72553bbe44e9eb89b399651ba8019" alt='咖啡' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">雀巢即饮咖啡渠道最多，上海老板偏爱进货三得利，乔雅咖啡地位尴尬</span>
                                        <span className="microcos-slide-times">2018/09/21</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/6T0v3HWA5UIZLFYsbwL25A" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/b0579e3b8d4141b59ad79f7226ca890d' alt='世界杯' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">世界杯期间6个最热门的啤酒品牌，啤酒行业微报告丨货圈全微数报告</span>
                                        <span className="microcos-slide-times">2018/08/02</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/OAXp7JO_v0qaGdbCrr3XxQ" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/fd2e6cc734414b91a85073c0912bcf2b' alt='品牌' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">最受欢迎的快消品品牌有哪些？|货圈全微数报告</span>
                                        <span className="microcos-slide-times">2018/07/04</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/96wbtTTz9BeZMR235-ZqTA" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/078d591b0e5a4212b858e35960c63129" alt='B2B' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">便利店老板都不睡觉？进货集中在深夜！快消B2B电商用户行为分析|货圈全微数报告</span>
                                        <span className="microcos-slide-times">2018/06/07</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/QtgugNk7Hv76sogEXd1TJQ" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/a2f1ee02ce2741ee8300869533d8999a" alt='进货' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">“物竞天择，适者生存”2018Q1快消B2B电商竞争力报告</span>
                                        <span className="microcos-slide-times">2018/05/03</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/Cm7P9AoTqpTmrj7e-QWWhA" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/a11be2bf1fc140cfbcd5b0d98848866b" alt='第一' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">康师傅、统一、农夫山泉…传统渠道饮料巨头到了电商怎么样？|快消品行业数据报告</span>
                                        <span className="microcos-slide-times">2018/03/14</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/o5iRayuEdhQnrfOtD5F8YA" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/c012784cc3f64876b287d5c2cfed2a98' alt='热销' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">饮用水怡宝一家销量占比41.2%，农夫才25.4%？全国传统通路快销品热销品牌Top100！2018年卖什么，货圈全告诉你！</span>
                                        <span className="microcos-slide-times">2019/02/14</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/dTUvmx5nBHjOiYTAun2dBQ" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/d0e15a2fdb4446ae8d43658a3bdd81db" alt='阿里' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">全国快消品B2B电商盘点 | 第二期</span>
                                        <span className="microcos-slide-times">2017/12/22</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/wBu_QDrMTlo3gnSaCLEStw" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/32e065b631b247c1802d6484de64681e' alt='快消品' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">10月数据报告|华南地区传统通路快消品热销品牌Top100</span>
                                        <span className="microcos-slide-times">2017/11/02</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/5wxae9UcWyKL_zNrXZy8mA" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/064b3ffa255446a8995cbf5c127d770c" alt='便利店' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">便利店成为新的“拿铁因素”丨这些年你在便利店多花了多少钱？</span>
                                        <span className="microcos-slide-times">2017/10/11</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/d1E-l9JwbV7EP2h9hlDxvg" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/a450a3b3323e4fc7be9dc8f228d5170b" alt='新零售' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">31个新零售物种盘点|45%有电商血统 北京成新零售之都</span>
                                        <span className="microcos-slide-times">2017/09/07</span>
                                    </div>

                                </a>
                            </div>
                        </div>

                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/SYD1snl7goIvq07sk4yeig" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/70e6d4bf3bfd4ff2aac5ccb767c0d8dc" alt='快消' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">全国71家快消品B2B电商盘点丨货圈全微数报告</span>
                                        <span className="microcos-slide-times">2017/08/15</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/ndxUVUvN9PXz4E_J5kb3NA" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/bb991524a40641ab8211721609f1d34c" alt='淘宝销量' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">6月快消品销量差异报告—2B vs 2C丨货圈全微数报告</span>
                                        <span className="microcos-slide-times">2017/07/17</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/U91hF8JV4jTQ8BVCVQj6JA" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/7c6d5b7c82154bd0ac1d4e65b355de61' alt='深圳' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">电商渠道哪家强？——深圳快消B2B电商竞争力报告</span>
                                        <span className="microcos-slide-times">2017/06/08</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/qzb4hzA7LHgqFAW7AZurXg" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/d37b8c8e728749ad82545c36e87da373" alt='促销' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">品牌商，88.5%的杂货铺欢迎你到店促销。你放心了么？|货圈全微数报告</span>
                                        <span className="microcos-slide-times">2017/05/04</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/i_x8CSB5BKI-VWMJDs76wA" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/23624643bee744d489657b904b5506d4' alt='农夫山泉' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">3月快消品品牌在传统通路与电商渠道销量对比分析报告出炉！零售人速看！</span>
                                        <span className="microcos-slide-times">2017/03/07</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/-HeAXQFcjhpRT14uJ1GR1A" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='https://hbsj-test.oss-cn-beijing.aliyuncs.com/58101814464545bebcecc71db689da00' alt='可口可乐' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">快消品最新消费者最爱和终端销量最佳大PK，快来看看区别在哪里！</span>
                                        <span className="microcos-slide-times">2017/03/07</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/Lfix9CQQUzc-Nucxabo7tg" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/454b22cf71db4878932ca3ec95b4411c" alt='北京超市' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">42.17%的店铺老板才20多岁！63.37％的老板有副业！最新出炉的杂货铺老板属性调研报告，速进！</span>
                                        <span className="microcos-slide-times">2017/03/07</span>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div className="swiper-slide slide">
                            <div style={{ width: 249, height: 249, border: '1px solid #efefef', borderRadius: '100%' }}>
                                <a href="https://mp.weixin.qq.com/s/S9jcngRGFBVPq7hragkkVg" className="microcos-slide-a-text">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/518e6f66f2534e93af57135f30935dd7" alt='酒水' className="microcos-slide-img" />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        {/* <img src={images.Shenp} alt='' style={{width:'45px',height:'45px'}} /> */}
                                        <span className="microcos-slide-text">最新出炉的快消品行业数据报告！可口可乐还是百事可乐？康师傅还是统一？什么？原来真相竟然是这样！</span>
                                        <span className="microcos-slide-times">2017/02/08</span>
                                    </div>

                                </a>
                            </div>
                        </div>


                    </div>

                </div>
                {/* <!--左箭头。如果放置在swiper-container外面，需要自定义样式。--> */}
                <div className="swiper-button-prev swiper-button-prevmic" style={{ outline: 'none', fontWeight: 'bold', position: 'absolute', marginTop: '130px', marginLeft: '130px', padding: '10px' }}>
                </div>
                {/* <!--右箭头。如果放置在swiper-container外面，需要自定义样式。--> */}
                <div className="swiper-button-next swiper-button-nextmic" style={{ outline: 'none', fontWeight: 'bold', position: 'absolute', marginTop: '130px', marginRight: '130px', padding: '10px' }}></div>
            </div>
        );
    }

}

/* <div style={{display:'flex',justifyContent:'center',alignItems:'center',position:'relative'}}>
       <img src={images.productBackground}  alt='' className="microcos-slide-img" />
       <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
           <span style={{fontSize:'24px',color:'#494444',marginTop:'20px'}}>HQ.Q-Digital</span>
           <span style={{color:'#ffffff',marginTop:'20px',lineHeight:'20px'}}>快消渠道数字化商业智能解决方案</span>
           <span style={{color:'#ffffff'}}>主要服务快消渠道的起点</span>
       </div>
   </div> */