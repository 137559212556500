import React from 'react';
// import images from '../../common/image'
// import colors from '../../common/color';
// import zIndexConf from '../../common/zIndexConfig';


export default class News extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){

    }

    showPage(index){
        if(this.props.showProductIndex){
            this.props.showProductIndex(index);
        }
    }

    render(){

        return(
            <div style={{width: '100vw',height:'100vh',background:'#333333',position:'absolute'}}>
                <div style={{padding:'0 20px',boxSizing:'border-box'}}>
                    <ul style={{listStyle:'none',marginTop:'27%'}}>
                        <a href="http://resource.hqq.vip/supplier_print_order/about.html" style={{textDecoration:'none',color:'#f5f5f5',fontSize:'14px'}}>
                            <li style={{height:'45px',lineHeight:'45px',borderBottom:'1px solid #666666',color:'#F5F5F5'}}>
                            关于货圈全
                            </li>
                        </a>
                        <li style={{height:'45px',lineHeight:'45px',borderBottom:'1px solid #666666',color:'#F5F5F5',fontSize:'14px'}} onClick={this.showPage.bind(this,1)}>产品介绍</li>
                        <li style={{height:'45px',lineHeight:'45px',borderBottom:'1px solid #666666',color:'#F5F5F5',fontSize:'14px'}} onClick={this.showPage.bind(this,2)}>新闻中心</li>
                        <a href="tel:400-014-7796" style={{textDecoration:'none',color:'#f5f5f5'}}>
                        <li style={{height:'45px',lineHeight:'45px',borderBottom:'1px solid #666666',fontSize:'14px'}}>客服电话</li>
                        </a>
                    </ul>
                    <div style={{display:'flex',flexDirection:'column',marginTop:'85px'}}>
                        <span style={{color:'#F5F5F5',lineHeight:'20px',fontSize:'14px'}}>客户端下载</span>
                        <a href="http://hbsj.hqq.vip/" style={{textDecoration:'none',marginTop:'12px',display:'block',color:'#979797',width:170,height:40,border:'1px solid #bcbcbc',lineHeight:'40px',textAlign:'center',}}>
                        <span style={{textAlign:'center',color:'#979797',fontSize:'14px'}}>
                            货比三价
                        </span></a>
                        <a href="http://sp.hqq.vip/" style={{marginTop:'25px',width:170,height:40,display:'block',border:'1px solid #bcbcbc',lineHeight:'40px',textAlign:'center',textDecoration:'none',color:'#979797'}}>
                        <span style={{color:'#979797',fontSize:'14px',}}>
                            神批
                        </span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
    
}