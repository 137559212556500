import React,{} from 'react'
// import colors from '../../common/color'
import Header from '../../components/header/header'
import MainView from '../../components/main/main'
import MenuList from '../../components/menulist/menulist'
import ProductIntroduction from '../../components/product/product'
import MicrocosmicLaboratory from '../../components/MicrocosmicLaboratory/MicrocosmicLaboratory'
import News from '../../components/news/news'
import City from '../../components/city/city'
import RelevantPages from '../../components/relevantpages/relevantpages'
import './home.scss'
import Swiper from 'swiper';
import '../../../node_modules/swiper/css/swiper.min.css'
import '../../../node_modules/swiper/js/swiper.min.js'
import zIndexConfig from '../../common/zIndexConfig';

export default class Homepage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            menuShowStatus:false,
            viewSwiper:'',
            currentIndex:0,
        };
    }

    componentDidMount(){
        var that = this;
        that.state.viewSwiper = new Swiper('.home-container', {
            direction: 'vertical',
            on:{
                slideChangeTransitionEnd: function(options) {
                    that.setState({
                        currentIndex:this.activeIndex
                    })
                }
            }
        })
    }

    showDetail(){
        this.props.history.push('/newsdetail');
    }

    showMenu(){
       this.setState({
        menuShowStatus:true,
       })
    }

    showNextView(){
        let that = this;
        this.state.viewSwiper.slideNext(500,()=>{
            that.setState({
                menuShowStatus:false,
            })
        });
    }

    showIndex(index){
        this.setState({
            menuShowStatus:false,
        })     
        this.state.viewSwiper.slideTo(index,500,()=>{
        });
    }

    hideView(){
        this.setState({
            menuShowStatus:false,
        })
    }

    showProduct(index){
        this.state.viewSwiper.slideTo(index,500,()=>{
        });
    }
    showMain(index){
        this.state.viewSwiper.slideTo(index,500,()=>{
        });
    }
    render(){
        return(
            <div style={{display:'flex',justifyContent:'center'}}>
                <Header pageName='home' currentIndex={this.state.currentIndex} showMenu={this.showMenu.bind(this)}/>
                <div className="swiper-container home-container">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide" ><MainView showMainIndex={this.showMain.bind(this)} /></div>
                        <div className="swiper-slide" style={{background:'#fff'}}><div><ProductIntroduction  /></div></div>
                        <div className="swiper-slide" style={{background:'#fff'}}><News /></div>
                        <div className="swiper-slide" style={{background:'#fff'}}><MicrocosmicLaboratory /></div>
                        <div className="swiper-slide" style={{background:'#fff'}}><City /></div>
                        <div className="swiper-slide" ><RelevantPages showProductIndex={this.showProduct.bind(this)}/></div>
                    </div>
                </div>
                <div currentindex={this.state.currentIndex} style={{width:41,height:41,position:'fixed',bottom:12,zIndex:zIndexConfig.menu}} onClick={this.showNextView.bind(this)}>
                    <img style={{width:41,height:41}} src={this.state.currentIndex === 0 ||this.state.currentIndex === 5?"https://hbsj-test.oss-cn-beijing.aliyuncs.com/3addf02a09d34a8597f66a9d6dfb9dc0":"https://hbsj-test.oss-cn-beijing.aliyuncs.com/7628b648304748ed8928281fd73e898d"} alt=""></img>
                </div>
                <MenuList isShow={this.state.menuShowStatus} hideView={this.hideView.bind(this)} showIndex={this.showIndex.bind(this)}/>
            </div>
        );
    }
}