import React from 'react';
import ReactDOM from 'react-dom';
import colors from '../../common/color';
import zIndexConf from '../../common/zIndexConfig';
import {CSSTransition} from 'react-transition-group';

import './menulist.scss';


export default class MenuList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isShow:false,
            list:[{cname:'首页',ename:'Home page',color:'#4B4B4D'},{cname:'产品介绍',ename:'Product introduction',color:'#3F3F41'},
                  {cname:'新闻中心',ename:'News Center',color:'#333336'},{cname:'微数实验室',ename:'Microcosmic Laborato',color:'#242426'}
                  ,{cname:'覆盖城市',ename:'Covered Cities',color:'#111111'}
                  ,{cname:'关于我们',ename:'About Us',color:'#000000'}
            ]
        };
    }
                                                                                                                                                                                                                                                           
    componentDidMount(){
        this.boxDOM = ReactDOM.findDOMNode(this.refs.box);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            isShow:newProps.isShow
        })                                                                                                                                                                                                                                                                                                                                                                                         
    }

    hideView(){
        this.setState({
            isShow:false
        })
        if(this.props.hideView){
            this.props.hideView();
        }
    }
    

    showIndex(index){
        if(this.props.showIndex){
            this.props.showIndex(index);
        }
        this.setState({
            isShow:false
        })
    }
    
    render(){
        return(
            <CSSTransition
                in={this.state.isShow}
                timeout={300}
                classNames="menu"
                unmountOnExit
            >
            <div style={{width: '100vw',height:'100vh',flexDirection:'column',position:'fixed',zIndex:zIndexConf.menu,top:0}}>
                {
                    this.state.list.map((item, index) => {
                        return (
                            <div style={{width:'100vw',height:'16.66%',background:item.color,display:'flex',flexDirection:'column',justifyContent:'center'}} key={index} onClick={this.showIndex.bind(this,index)}>
                                <div style={{fontSize:16,color:colors.white,marginLeft:55}}>{item.cname}</div>
                                <div style={{fontSize:16,color:'#979797',marginLeft:55,marginTop:8}}>{item.ename}</div>
                            </div>
                        )
                    })
                }
                <div style={{position:'absolute',zIndex:zIndexConf.menu,top:0,right:0,width:40,height:40}} onClick={this.hideView.bind(this)}>
                    <img style={{width:40,height:40}} src="https://hbsj-test.oss-cn-beijing.aliyuncs.com/f90f1c4c344947f0b4bdd883d280070a" alt=''/>
                </div>
            </div>
        </CSSTransition>
            
        );
    }
    
}